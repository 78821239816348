











import {computed, defineComponent} from "@vue/composition-api";
import {snackBarService} from "./SnackBarService";

export default defineComponent({
	name: "SnackBarComponent",
	setup() {
		const model = computed({
			get() {
				return snackBarService.model
			},
			set(val: boolean) {
				snackBarService.model = val;
			}
		});
		const color = computed(
				() => snackBarService.getColor()
		);
		const message = computed(
				() => snackBarService.getMessage()
		);
		return {
			model,
			color,
			message
		}
	}
});
