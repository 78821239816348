




















































































import {
	defineComponent,
	ref,
	Ref,
	SetupContext, watch
} from "@vue/composition-api";
import { IResultSet }                 from "@axional/ax-resultset";
import { axiosInstance }              from "@/plugins/axios-instance";
import { API_PREFIX_APP }             from "@/constants";
import AxGrid, {AxApiGrid}            from "@axional/ax-grid";
import AxForm                         from "@axional/ax-form";
import { QBEView }                    from "@axional/ax-qbe";
import ResultSetHandler               from "@/pages/object/components/ResultSetHandler";
import {userService} from "@/services/user/User";

export default defineComponent({
  name: "FormComponent",
  props: {
    data: {
      type: Object as () => IResultSet,
      required: true
    },
    childUrl: {
      type: String
    }
  },
  components: {
  	AxGrid,
    AxApiGrid,
    AxForm,
		QBEView
  },
  setup(props, context: SetupContext) {
		const userLang: string = userService.getUserLang();

		/**
		 *
		 */
		const loadingData: Ref<boolean> = ref(false);

		/**
		 *
		 */
    const resultSetHandler = ref(new ResultSetHandler(props.data,  context.root.$route.path));

    /**
     *
     */
    const childData: Ref<ResultSetHandler | null> = ref(null);

    /**
     *
     */
    const loadingChildData: Ref<boolean> = ref(false);

		const renderKey:Ref<number> = ref(0);
		const renderKeyChild:Ref<number> = ref(0);

    /**
     *
     */
    function onClear(): void {
      childData.value = null;
    }

    /**
     *
     * @param cursor
     */
    function onCursorChange(cursor: Record<string, any>): void {
      fetchItems(cursor);
    }

		/**
		 *
		 */
		async function executeQuery(url: string, params: Record<string, any>) :Promise<void>
		{
			try {
				loadingData.value = true;
				await resultSetHandler.value.fetchData(params);
			} catch (e) {
				console.log("GridComponent fetchData from qbe error:", e.message)
			} finally {
				loadingData.value = false;
			}
		}

    /**
     *
     * @param cursor
     */
    async function fetchItems(cursor: Record<string, any>) {
      if (props.childUrl) {
        // IN : "order/{order_num}".match(/\{(.*)\}/).pop()
        // OUT: "order_num"
        const matcher = props.childUrl.match(/\{(.*)\}/);
        if (matcher) {
          const columnName = matcher.pop();
          if (columnName) {
            const columnValue = cursor[columnName];
            const url = props.childUrl.replace("{" + columnName + "}", columnValue);
            try {
              loadingChildData.value = true;
              const response = await axiosInstance.get(API_PREFIX_APP + url);
							renderKeyChild.value++;
							// TODO: review if response data is not null
              childData.value = new ResultSetHandler(response.data, url);
            } finally {
              loadingChildData.value = false;
            }
          }
        }
      }
    }

		watch(() => props.data, () => {
			renderKey.value++
		})

		return {
			userLang,
      resultSetHandler,
      childData,
			loadingData,
      loadingChildData,
      onClear,
      onCursorChange,
			executeQuery,
			renderKey,
			renderKeyChild
    }
  }
});
