












































































import {
	computed,
	defineComponent, onMounted,
	Ref,
	ref,
	SetupContext,
	shallowRef,
	watch
} from "@vue/composition-api";
import { IResultSet, ResultSet }        from "@axional/ax-resultset";
import { axiosInstance }                from "@/plugins/axios-instance";
import { API_PREFIX_APP }               from "@/constants";
import { AxApiGrid, RowSelectionMode }  from "@axional/ax-grid";
import { QBEView }                      from "@axional/ax-qbe";
import ResultSetHandler                 from "@/pages/object/components/ResultSetHandler";
import {userService} from "@/services/user/User";

export default defineComponent({
  name: "GridComponent",
  props: {
    /**
     *
     */
    data: {
      type: Object as () => IResultSet,
      required: true
    },
    /**
     *
     */
    childUrl: {
      type: String
    },
  },
  components: {
    AxApiGrid,
		QBEView
  },
  setup(props, context: SetupContext) {

  	/**
		 *
		 */
		const loadingData: Ref<boolean> = ref(false);

		/**
		 *
		 */
		const userLang: string = userService.getUserLang();

		/**
     *
     */
    const resultSetHandler:Ref<ResultSetHandler | null> = ref(null);

    /**
     *
     */
    const selectedRows:Ref<number[]> = ref([]);

    const renderKey:Ref<number> = ref(0);
    const renderKeyChild:Ref<number> = ref(0);

    /**
     *
     */
    const childData: Ref<ResultSetHandler | null> = shallowRef(null);

    /**
     *
     */
    const loadingChildData: Ref<boolean> = ref(false);

    /**
		 *
		 */
    async function executeQuery(url: string, params: Record<string, any>) :Promise<void>
		{
			try {
				loadingData.value = true;
				await resultSetHandler.value?.fetchData(params);
			} catch (e) {
				console.log("GridComponent fetchData from qbe error:", e.message)
			} finally {
				loadingData.value = false;
			}
		}

    /**
     * On row select fetch child data using the child url from menuItem
     */
    async function onRowSelected() {
      if (props.childUrl && selectedRows.value.length > 0) {

        // Take the firs value because in this component we only allow one selected row
        const row = selectedRows.value[0];
        // IN : "order/{order_num}".match(/\{(.*)\}/).pop()
        // OUT: "order_num"
        const matcher = props.childUrl.match(/\{(.*)\}/);
        if (matcher) {
          // Replace child URL PK (/orders/items/{order_num} -> /orders/items/10
          const columnName = matcher.pop();
          if (columnName) {
            const resultSet = new ResultSet(props.data);
            const columnNumber = resultSet.getMetaData().getColumnNumber(columnName);
            const columnValue = resultSet.getValueAt(row, columnNumber);
            const url = props.childUrl.replace("{" + columnName + "}", columnValue);

            try {
              loadingChildData.value = true;
							renderKeyChild.value++;
              const response = await axiosInstance.get(API_PREFIX_APP + url);
              childData.value = new ResultSetHandler(response.data, url);
            } finally {
              loadingChildData.value = false;
            }
          }
        }
      } else {
        childData.value = null;
      }
    }

    /**
     *
     */
    watch(
      () => selectedRows.value, () => onRowSelected()
    );

    watch(() => props.data, () => {
			renderKey.value++
		});


    watch(() => context.root.$route.path, () => {
			resultSetHandler.value = new ResultSetHandler(props.data, context.root.$route.path)
		}, {
    	immediate: true
		})

    return {
			userLang,
      resultSetHandler,
      selectedRows,
      childData,
      loadingChildData,
      RowSelectionMode,
			loadingData,
			executeQuery,
			renderKey,
			renderKeyChild
    }
  }
});
