


























import { defineComponent, SetupContext } from "@vue/composition-api";

export default defineComponent({
  name: "feature-card.component",
  props: {
    card: {
      type: Object,
      required: true
    }
  },
  setup(props, context: SetupContext) {

    function goToPage():void {
      if (props.card.card_url) {
        context.root.$router.push({
          path: props.card.card_url,
        });
      }
    }

    return {
      goToPage
    }
  }
})
