







import { defineComponent }        from "@vue/composition-api";
import { IResultSet, ResultSet }  from "@axional/ax-resultset";
import { ChartsResultSet, AxCharts, StackedBars }  from "@axional/ax-charts";

export default defineComponent({
  name: "ChartsComponent",
  props: {
    data: {
      type: Object as () => IResultSet,
      required: true
    }
  },
  components: {
    AxCharts,
    StackedBars
  },
  setup(props) {
    const chart = new ChartsResultSet(new ResultSet(props.data));
    const config = chart.getChartConf();
    const dataset = chart.getChartDataSet();

    return {
      config,
      dataset
    }
  }
});
