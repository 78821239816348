import Vue from "vue";
import { Component } from "vue-property-decorator";
import { logService } from "@/api/logger/Logger";
import { RouteMeta } from "vue-router";
import { Observer } from "@/api/observer/Observer";
import { NotificationModel } from "@/components/app-bar/notifications/NotificationModel";
import { notificationsService } from "@/services/NotificationsService";
import NotificationCard from "@/pages/notifications/components/NotificationCard.vue";

enum NotifyType {
  ALL,
  SEEN,
  UNSEEN
}

@Component({
  components: {
    NotificationCard
  }
})
export default class NotificationsComponent extends Vue implements Observer {
  private m_selectedTab: number;

  private m_notifications: NotificationModel[];

  public constructor() {
    super();
    const routeMeta: RouteMeta | undefined = this.$route.meta;
    logService.log(`${NotificationsComponent.name} - created.`);

    this.m_selectedTab = 0;

    if (routeMeta && routeMeta.data) {
      this.m_notifications = routeMeta.data as NotificationModel[];
    } else {
      this.m_notifications = [];
    }

    notificationsService.attach(this);
  }

  public update(): void
  {
    this.__fetchNotifications();
  }

  public async refresh() {
    notificationsService.notify();
  }

  public async __fetchNotifications(): Promise<void>
  {
    this.m_notifications = await notificationsService.getPageData();
  }

  public getAllNotifications(): NotificationModel[] {
    return this.m_notifications;
  }

  public getSeenNotifications(): NotificationModel[] {
    return this.m_notifications.filter((n: any) => n.seen == 1);
  }

  public getUnseenNotifications(): NotificationModel[] {
    return this.m_notifications.filter((n: any) => n.seen == 0);
  }

  public get selectedTab() {
    return this.m_selectedTab;
  }

  public set selectedTab(tab) {
    this.m_selectedTab = tab;
  }

  public get tabColor() {
    switch (this.m_selectedTab) {
      case NotifyType.ALL:
        return "blue";
      case NotifyType.SEEN:
        return "green";
      case NotifyType.UNSEEN:
        return "red";
    }
  }

  /**
   *
   * @param type
   */
  public getNotifyCount(type: NotifyType): number | undefined {
    switch (type) {
      case NotifyType.ALL:
        return this.getAllNotifications().length;
      case NotifyType.SEEN:
        return this.getSeenNotifications().length;
      case NotifyType.UNSEEN:
        return this.getUnseenNotifications().length;
      default:
        console.warn("Unsupported notify type: ", type);
        break;
    }
  }

  public getNotificationTabs() {
    return [
      {
        type: NotifyType.ALL,
        title:	this.$i18n.t('ID_NOTIFICATIONS_ALL') ,
        icon: "mdi-calendar",
        color: "#448AFF",
      },
      {
        type: NotifyType.SEEN,
        title: this.$i18n.t('ID_NOTIFICATIONS_SEEN'),
        icon: "mdi-check-circle-outline",
        color: "#4CAF50",
      },
      {
        type: NotifyType.UNSEEN,
        title: this.$i18n.t('ID_NOTIFICATIONS_NOT_SEEN'),
        icon: "mdi-information-outline",
        color: "#F44336",
      }
    ];
  }
}