

















import { defineComponent }  from "@vue/composition-api";
import LearnCard from "@/components/app-menu/impl/page/content/LearnCard";

export default defineComponent({
  name: "feature-video.component",
  props: {
    data: {
      type: LearnCard,
      required: true
    }
  }
})
