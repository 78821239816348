








































































import { computed, defineComponent } from "@vue/composition-api";
import ObjectHeaderContent from "@/pages/object/components/header/object-header-content.component.vue";
import { API_PREFIX_MAIN } from "@/constants";
import { axiosInstance } from "@/plugins/axios-instance";
import MenuItem from "@/components/app-menu/impl/MenuItem";

export default defineComponent({
  name: "object-header.component",
  props: {
    isPageSeen: {
      type: Boolean
    },
		menuItem: {
			type: MenuItem,
			required: true
		}
  },
  components: {
    ObjectHeaderContent
  },
	setup(props) {

  	const options = computed(() => props.menuItem.getItemPage());

  	async function markAsSeen() {
			// Get the current page id.
			const page: number = props.menuItem.getItemPageId();

			// Perform API call to mark current page as seen.
			const response = await axiosInstance.post(
					`${API_PREFIX_MAIN}/menu/page/${page}/mark/seen`,
					{
						headers: {
							"content-type": "application/json"
						}
					}
			);

			// Mark page as seen if transaction is successful.
			if (response.data) {
				props.menuItem.markPageAsSeen(page);
			}
		}

  	return {
			markAsSeen,
			options
		}
	}
})
