import {
	IResultSet, IResultSetColumnMetaData,
	ResultSetCursor
} from "@axional/ax-resultset";
import { API_PREFIX_APP } 				from "@/constants";
import { axiosInstance } 					from "@/plugins/axios-instance";
import { snackBarService } 				from "@/components/snackbar/SnackBarService";
import { columnsToQBEFields } 		from "@axional/ax-qbe";
import { IQBEField } 							from "@axional/ax-qbe/dist/main/impl/fields/IQBEField";

export default class ResultSetHandler {

	/**
	 *
	 * @private
	 */
	private readonly m_rs: ResultSetCursor;

	/**
	 *
	 * @param rs
	 * @param requestUrl
	 */
	public constructor(rs: IResultSet, requestUrl: string)
	{
		this.m_rs = new ResultSetCursor(rs);
		this.__buildTransactionHandler(requestUrl)
	}

	/**
	 * Returns a ResultSetCursor with a transaction handler
	 */
	public getResultSet(): ResultSetCursor
	{
		return this.m_rs;
	}

	/**
	 *
	 */
	public hasOptionsParams(): boolean
	{
		return this.m_rs.getTransactionManager().getFetchOptionParameters().length > 0;
	}

	/**
	 * Returns a ResultSetCursor with a transaction handler
	 */
	public getOptionsParams(): IResultSetColumnMetaData[]
	{
		return this.m_rs.getTransactionManager().getFetchOptionParameters();
	}

	/**
	 * Get QBE params
	 */
	public getQBEParams(): IQBEField[]
	{
		// TODO: use IResultSetColumnMetaData instead of IBaseResultSetColumnMetaData[] for remove any
		return columnsToQBEFields(this.getOptionsParams() as any)
	}

	/**
	 *
	 */
	public async fetchData(params: Record<string, any>): Promise<void>
	{
		if(this.m_rs.hasTransactionManager()) {
			await	this.m_rs.getTransactionManager().fetchData(params)
		}
	}

	/**
	 *
	 * @private
	 */
	private __buildTransactionHandler(requestUrl: string): void
	{
		this.m_rs.setTransactionHandler({
			url: `${API_PREFIX_APP}${requestUrl}` ,

			async fetchData(url: string, params?: Record<string, any>) {
				const {data} = await axiosInstance.get(url, {params: params});
				return data;
			},

			async fetchOptions(url: string) {
				const {data} = await axiosInstance.options(url);
				return data;
			},

			async onInsert(url: string, params: Record<string, any>) {
				const {data} = await axiosInstance.post(url, params);
				snackBarService.show("Success", "success");
				return data;
			},

			async onDelete(url: string, params: Record<string, any>) {
				const {data} = await axiosInstance.delete(url, {
					params
				});
				snackBarService.show("Success", "success");
				return data;
			},

			async onUpdate(url: string, params: Record<string, any>) {
				const {data} = await axiosInstance.put(url, params);
				snackBarService.show("Success", "success");
				return data;
			},

			async onActionExecDelete(url: string, params: Record<string, any>) {
				const {data} = await axiosInstance.delete(url, {
					params
				});
				snackBarService.show("Success", "success");
				return data;
			},

			async onActionExecGet(url: string, params: Record<string, any>) {
				const {data} = await axiosInstance.get(url, {
					params
				});
				return data;
			},

			async onActionExecPost(url: string, params: Record<string, any>) {
				const {data} = await axiosInstance.post(url, params);
				snackBarService.show("Success", "success");
				return data;
			},

			async onActionExecPut(url: string, params: Record<string, any>) {
				const {data} = await axiosInstance.put(url, params);
				snackBarService.show("Success", "success");
				return data;
			},

			async onProcess(url: string) {
				const {data} = await axiosInstance.post(url, {});
				return data;
			},
		})
	}
}