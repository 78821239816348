import Vue from "vue";
import { Component, Watch }           from "vue-property-decorator";
import { Ref, ref }                   from "@vue/composition-api";
import { IResultSet, ResultSet }      from "@axional/ax-resultset";
import { Route, RouteMeta }           from "vue-router";
import { AxiosRequestConfig }         from "axios";
import router                         from "@/routes";

// Services
import { applicationService }         from "@/services/ApplicationService";
import { objectService }              from "@/pages/object/ObjectService";

// Components
import HTMlFragmentComponent          from "@/pages/object/components/html-fragment/HTMLFragmentComponent.vue";
import GridComponent                  from "@/pages/object/components/grid/GridComponent.vue";
import ChartsComponent                from "@/pages/object/components/charts/ChartsComponent.vue";
import FormComponent                  from "@/pages/object/components/form/FormComponent.vue";
import ObjectHeader                   from "@/pages/object/components/header/object-header.component.vue";


import MenuRoot                       from "@/components/app-menu/impl/MenuRoot";
import MenuItem                       from "@/components/app-menu/impl/MenuItem";
import MenuItemTabs                   from "@/components/app-menu/impl/MenuItemTabs";
import SnackBarComponent              from "@/components/snackbar/SnackBarComponent.vue";

@Component({
  components: {
    ObjectHeader,
    SnackBarComponent
  }
})
export default class ObjectComponent extends Vue {
  /**
   *
   * @private
   */
  private data: Ref<IResultSet | null>;

  /**
   *
   * @private
   */
  private renderComponent: Ref<any>;

  /**
   *
   * @private
   */
  private menuItem: MenuItem | null;

  /**
   *
   * @private
   */
  private tabs: Ref<MenuItemTabs | null>;

  /**
   *
   * @private
   */
  private selectedTab: Ref<number>;

  /**
   *
   */
  public constructor() {
    super();
    this.data = ref(null);
    this.renderComponent = ref(null);
    this.menuItem = null;
    this.tabs = ref(null);
    this.selectedTab = ref(0);
  }

  /**
   *
   * @param route
   */
  @Watch("$route", { immediate: true, deep: true })
  async onRouteMetaChange(route: Route)
  {
    const meta: RouteMeta | undefined = route.meta;
    if (meta != null && meta.data != null) {
      this.__setData(meta.data);
      this.__generateTabItems(route.path);
      objectService.setLoading(false);
    } else {
      this.data.value = null;
      this.tabs.value = null;
    }
    this.__setMenuItem(route.path);
  }

  /**
   *
   */
  public isLoading(): boolean
  {
    return objectService.isLoading();
  }

  /**
   *
   */
  public hasError(): boolean
  {
    return objectService.hasError();
  }

  /**
   *
   */
  public getData(): IResultSet | null
  {
    return this.data.value;
  }

  /**
   *
   */
  public getRenderComponent() {
    return this.renderComponent.value;
  }

  // ==========================================================================
  // Menu item tabs
  // ==========================================================================

  /**
   *
   */
  public getTabs(): MenuItemTabs | null
  {
    return this.tabs.value;
  }

  /**
   *
   */
  public get tab() {
    return this.selectedTab.value;
  }

  /**
   *
   * @param tabIndex
   */
  public set tab(tabIndex: number) {
    this.selectedTab.value = tabIndex;
  }

  // ==========================================================================
  // Menu item header
  // ==========================================================================

  /**
   *
   */
  public getMenuItem(): MenuItem | null
  {
    return this.menuItem;
  }

  /**
   *
   */
  public hasHeader(): boolean
  {
    if (this.menuItem == null)
      return false;
    return this.menuItem.hasItemPageHeader();
  }

  /**
   *
   */
  public hasHeaderContent(): boolean
  {
    if (this.menuItem == null)
      return false;
    return this.menuItem.hasItemPageContent();
  }

  /**
   *
   */
  public changeRoute(item: MenuItem): void {
    if (router.currentRoute.path !== item.getItemUrl()) {
      router.push({ path: item.getItemUrl() });
    }
  }

  // ==========================================================================
  // PRIVATE
  // ==========================================================================

  /**
   *
   * @param path
   */
  public __generateTabItems(path: string): void
  {
    const menu = applicationService.getApplicationMenu();

    // Search the menu item matching the route.
    const item = menu?.getMenuItem(path);
    const itemTabs = item?.getTabItems(item);

    if (item != null && itemTabs != null) {
      this.tabs.value = itemTabs;
      this.selectedTab.value = itemTabs.getTabIndex(item.getItemId());
    } else {
      this.tabs.value = null;
    }
  }

  /**
   *
   * @param response
   * @private
   */
  private __setData(response: AxiosRequestConfig): void
  {
    const contentType: string = response.headers["content-type"];
    const data = response.data;


    if (contentType.startsWith("text/html")) {
      if (contentType == "text/html;charset=UTF-8;mode=fragment") {
        this.renderComponent.value = HTMlFragmentComponent;
      } else {
        var windowElement = window.open('',"_self" );
        if(windowElement) {
          windowElement.document.write(data);
        }
      }
    } else if (contentType.startsWith("application/json")) {
      if (ResultSet.isResultSet(data)) {
        this.renderComponent.value = ObjectComponent.__getRenderComponent(this.data.value?.attributes);
      }
    } else {
      console.warn("Unsupported render for content type: ", contentType);
    }

    this.data.value = data;
  }

  /**
   *
   * @param path
   * @private
   */
  private __setMenuItem(path: string): void
  {
    const menu: MenuRoot | null = applicationService.getApplicationMenu();
    if (menu != null) {
      this.menuItem = menu.getMenuItem(path);
    }
  }

  /**
   *
   * @param attributes
   * @private
   */
  private static __getRenderComponent(attributes: any)
  {
    if (attributes == null){
      return GridComponent;
    }

    if (attributes.grid) {
      return GridComponent;
    } else if (attributes.form) {
      return FormComponent;
    } else if (attributes.charts) {
      return ChartsComponent;
    } else {
      return GridComponent;
    }
  }
}