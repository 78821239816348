































































import { defineComponent, ref, Ref } from "@vue/composition-api";

export default defineComponent({
  name: "ErrorPage",
  props: {
    error: {
      type: Error,
      required: true
    }
  },
  setup() {
    /**
     * Controls the stacktrace message visibility status.
     */
    const showStackTrace: Ref<boolean> = ref(false);

    /**
     * Reloads page.
     */
    function reload(): void {
      location.reload();
    }

    return {
      showStackTrace,
      reload
    }
  }
});
