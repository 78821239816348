<template>
	<iframe
			:src="'data:text/html;charset=utf-8,' + encodeURI(data)"
			width="100%"
			height="100%"
			frameBorder="0"
	></iframe>
</template>

<script>
import { defineComponent } from "@vue/composition-api";

export default defineComponent({
	name: "HTMLFragment",
	props: {
		data: {
			type: String,
			required: true
		}
	}
});
</script>

<style scoped>

</style>