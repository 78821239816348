






























import { defineComponent }  from "@vue/composition-api";
import LearnCard            from "@/components/app-menu/impl/page/content/LearnCard";

export default defineComponent({
  name: "learn-list.component",
  props: {
    data: {
      type: LearnCard,
      required: true
    }
  },
  setup() {
    function goToPage(url: string):void {
      if (url) {
        window.open(url);
      }
    }

    return {
      goToPage
    }
  }
})
