import { render, staticRenderFns } from "./feature-video.component.vue?vue&type=template&id=346d2242&scoped=true&"
import script from "./feature-video.component.vue?vue&type=script&lang=ts&"
export * from "./feature-video.component.vue?vue&type=script&lang=ts&"
import style0 from "./feature-video.component.vue?vue&type=style&index=0&id=346d2242&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "346d2242",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
installComponents(component, {VCard})
