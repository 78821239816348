


















































import { defineComponent }  from "@vue/composition-api";
import PageContent          from "@/components/app-menu/impl/page/content/PageContent";
import LearnList            from "@/pages/object/components/header/components/learn-list.component.vue";
import LearnVideo           from "@/pages/object/components/header/components/feature-video.component.vue";
import FeatureCard          from "@/pages/object/components/header/components/feature-card.component.vue";

export default defineComponent({
  name: "object-header-content.component",
  props: {
    content: {
      type: PageContent,
      required: true
    }
  },
  components: {
    LearnList,
    LearnVideo,
    FeatureCard,
  }
});
