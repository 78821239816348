import Vue from "vue";
import { Component } from "vue-property-decorator";
import { API_PREFIX_MAIN } from "@/constants";
import { logService } from "@/api/logger/Logger";
import { ref, Ref } from "@vue/composition-api";
import axios from "axios";

@Component({
  props: {
    notification: {
    }
  }
})
export default class NotificationCard extends Vue {
  private marking_as_seen: Ref<boolean>;

  public constructor() {
    super();
    this.marking_as_seen = ref(false);
  }

  public markingAsSeen(): boolean{
    return this.marking_as_seen.value;
  }

  /**
   *
   */
  public isNotSeen(): boolean
  {
    return this.$props.notification.seen == 0;
  }

  /**
   *
   */
  public getNotifyColor(): string | undefined {
    const seen = this.$props.notification.seen;
    if (seen == 1) {
      return "green";
    } else if (seen == 0) {
      return "red";
    }
  }

  /**
   *
   */
  public goToLink(): void
  {
    const url = this.$props.notification.link_url;
    if(url) {
      if(url.includes("https") || url.includes("http") || url.includes("www")) {
        window.open(url)
      } else {
        this.$router.push({path: url});
      }
    }
  }

  /**
   *
   */
  public async markNotificationAsSeen(): Promise<void>
  {
    const notify_id = this.$props.notification.notify_id;
    try {
      this.marking_as_seen.value = true;

      // Mark notification as seen.
      await axios.put(`${API_PREFIX_MAIN}/notification/mark/seen/${notify_id}`);

      // Fetch notifications to refresh list once notification has been marked as seen.
      this.$emit("refresh");
    } catch (e) {
      logService.error(`${NotificationCard.name} - error marking notification (id=${notify_id}) as seen: ${e}`);
    } finally {
      this.marking_as_seen.value = false;
    }
  }
}