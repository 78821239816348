import { Ref, ref } from "@/plugins/composition-api";

class SnackBarService {
  private m_model: Ref<boolean>;
  private m_message: Ref<string>;
  private m_color: Ref<string | undefined>;

  public constructor() {
    this.m_model = ref(false);
    this.m_message = ref("");
    this.m_color = ref("");
  }

  public show(msg: string, color?: string) {
    this.m_model.value = true;
    this.m_message.value = msg;
    this.m_color.value = color;
  }

  public get model() {
    return this.m_model.value;
  }

  public set model(val: boolean) {
    this.m_model.value = val;
  }

  public getColor(): string | undefined {
    return this.m_color.value;
  }

  public getMessage(): string {
    return this.m_message.value;
  }
}

export const snackBarService = new SnackBarService();